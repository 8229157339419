<template>
  <div
    class="mt-45 lg:mt-90 mb-30 lg:mb-180 lg:h-600 flex flex-col items-center justify-end lg:justify-start"
  >
    <div
      class="w-330 lg:w-444 lg:h-168 text-right self-end lg:self-start flex flex-col relative lg:ml-120 mb-60"
    >
      <h2
        class="uppercase text-white font-black text-right lg:text-center text-8xl lg:text-10xl"
      >
        Utilities
      </h2>

      <span
        class="uppercase text-white font-black text-end text-2xl lg:text-4xl absolute -bottom-4 right-1 lg:-bottom-1 lg:right-6"
        >NFT</span
      >
    </div>

    <UtilitiesCarrousel class="hidden lg:inline-block"></UtilitiesCarrousel>
    <UtilitiesCarrouselMobile
      class="inline-block lg:hidden"
    ></UtilitiesCarrouselMobile>
  </div>
</template>

<script>
import UtilitiesCarrousel from "../components/UtilitiesCarrousel.vue";
import UtilitiesCarrouselMobile from "../components/UtilitiesCarrouselMobile.vue";

export default {
  name: "Utilities",
  components: { UtilitiesCarrousel, UtilitiesCarrouselMobile },
};
</script>
