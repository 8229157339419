<template>
  <div
    class="container-3d mb-60 lg:mb-0 w-full h-full flex flex-col justify-center relative"
  >
    <div class="hidden lg:flex justify-around w-full px-150 absolute bottom-40">
      <img
        src="../assets/icons/arrow-left.svg"
        alt="arrow left"
        class="pointer-events-none z-30"
      />
      <img
        src="../assets/icons/arrow-right.svg"
        alt="arrow right"
        class="pointer-events-none z-30"
      />
    </div>
    <input type="radio" name="slider" id="item-1" checked />
    <input type="radio" name="slider" id="item-2" />
    <input type="radio" name="slider" id="item-3" />
    <input type="radio" name="slider" id="item-4" />
    <input type="radio" name="slider" id="item-5" />
    <div class="cards relative h-350 lg:h-full">
      <label class="card" for="item-1" id="utilitie-1">
        <!-- Holding -->

        <div
          class="w-255 h-300 lg:w-380 lg:h-420 bg-gray3 flex flex-col justify-start radio"
        >
          <div class="p-6 lg:p-10">
            <span
              class="text-3xl lg:text-8xl font-black text-red1 uppercase leading-14 lg:leading-16"
              >Get Tokens for Holding
            </span>
          </div>
          <div class="px-6 lg:px-10 pb-6 lg:pb-10 pt-3 lg:pt-0">
            <p class="text-white text-base lg:text-lg">
              As long as you own a Circumstancer NFT, you will passively emit
              our Utility Token: <b>MAGNA.</b>
            </p>
          </div>
        </div>
      </label>
      <label class="card" for="item-2" id="utilitie-2">
        <!-- Vip Member -->

        <div
          class="w-255 h-300 lg:w-380 lg:h-420 bg-gray3 flex flex-col justify-start radio"
        >
          <div class="pt-6 px-6 lg:p-9 pr-10 lg:pr-28">
            <span
              class="text-4xl lg:text-8xl font-black text-yellow1 uppercase leading-14 lg:leading-16"
              >vip member</span
            >
          </div>
          <div class="px-6 lg:px-10 pb-6 lg:pb-10">
            <p class="text-white text-base lg:text-lg">
              Having a Circumstancer NFT makes you a VIP member of our
              community. Access the VIP section, take advantage of the utilities
              of your NFT, make use of the generated MAGNAs and get ready for
              many surprises!
            </p>
          </div>
        </div>
      </label>
      <label class="card" for="item-3" id="utilitie-3">
        <!-- PRIORITY ACCESS:  -->

        <div
          class="w-255 h-300 lg:w-380 lg:h-420 bg-gray3 flex flex-col justify-start radio"
        >
          <div class="p-6 lg:p-10">
            <span
              class="text-4xl lg:text-8xl font-black text-pink1 uppercase leading-14 lg:leading-16"
              >PRIORITY ACCESS
            </span>
          </div>
          <div class="px-6 lg:px-10 pb-6 lg:pb-10">
            <p class="text-white text-base lg:text-lg">
              Access before anyone else to WL, Sweepstakes, pre-sale of all 369
              projects, and privileges to postulate your project to the
              community and have it developed by 369 Incubator.
            </p>
          </div>
        </div>
      </label>
      <label class="card" for="item-4" id="utilitie-4">
        <!-- VOICE AND VOTE: -->

        <div
          class="w-255 h-300 lg:w-380 lg:h-420 bg-gray3 flex flex-col justify-start radio"
        >
          <div class="p-6 lg:p-10">
            <span
              class="text-4xl lg:text-8xl font-black text-green1 uppercase leading-14 lg:leading-16"
              >VOICE AND VOTE
            </span>
          </div>
          <div class="px-6 lg:px-10 pb-6 lg:pb-10">
            <p class="text-white text-base lg:text-lg">
              Vote, give your opinion, participate in decision making within
              369. <br />Your voice counts in all cases: from alliances,
              projects to Incubate and launches.
            </p>
          </div>
        </div>
      </label>
      <label class="card" for="item-5" id="utilitie-5">
        <!--  WHAT THE COMMUNITY WANTS? -->

        <div
          class="w-255 h-300 lg:w-380 lg:h-420 bg-gray3 flex flex-col justify-center radio"
        >
          <div class="p-6 lg:p-10">
            <span
              class="text-4xl lg:text-8xl font-black text-aqua uppercase leading-14 lg:leading-16"
              >WHAT THE COMMUNITY WANTS?
            </span>
          </div>
          <div class="px-6 lg:px-10 pb-6 lg:pb-10">
            <p class="text-white text-base lg:text-lg">
              We are open to suggestions and opinions from our community, many
              more things can be added and done as members request. Can you
              imagine?
            </p>
          </div>
        </div>
      </label>
    </div>
    <div class="dots">
      <label for="item-1"></label>
      <label for="item-2"></label>
      <label for="item-3"></label>
      <label for="item-4"></label>
      <label for="item-5"></label>
    </div>
  </div>
</template>

<script>
export default {
  name: "UtilitiesCarrousel",
};
</script>

<style scoped>
input[type="radio"] {
  display: none;
}

.radio {
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.container-3d {
  transform-style: preserve-3d;
}

/* @media (min-width: 375px) {
  .card {
    position: absolute;
    width: 255px;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform 0.4s ease;
    cursor: pointer;
  }

  .dots {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dots label {
    display: block;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #eaeaea;
    margin: 0px 15px;
    transition: transform 0.2s, color 0.2s;
  }

  #item-1:checked ~ .cards #utilitie-5,
  #item-2:checked ~ .cards #utilitie-1,
  #item-3:checked ~ .cards #utilitie-2,
  #item-4:checked ~ .cards #utilitie-3,
  #item-5:checked ~ .cards #utilitie-4 {
    transform: translatex(-50px) scale(0.8);
    opacity: 0.6;
    z-index: 1;
  }
  #item-1:checked ~ .cards #utilitie-4,
  #item-2:checked ~ .cards #utilitie-5,
  #item-3:checked ~ .cards #utilitie-1,
  #item-4:checked ~ .cards #utilitie-2,
  #item-5:checked ~ .cards #utilitie-3 {
    transform: translatex(-80px) scale(0.7);
    opacity: 0.2;
    z-index: 0;
  }

  #item-1:checked ~ .cards #utilitie-2,
  #item-2:checked ~ .cards #utilitie-3,
  #item-3:checked ~ .cards #utilitie-4,
  #item-4:checked ~ .cards #utilitie-5,
  #item-5:checked ~ .cards #utilitie-1 {
    transform: translatex(50px) scale(0.8);
    opacity: 0.6;
    z-index: 1;
  }
  #item-1:checked ~ .cards #utilitie-3,
  #item-2:checked ~ .cards #utilitie-4,
  #item-3:checked ~ .cards #utilitie-5,
  #item-4:checked ~ .cards #utilitie-1,
  #item-5:checked ~ .cards #utilitie-2 {
    transform: translatex(80px) scale(0.7);
    opacity: 0.2;
    z-index: 0;
  }

  #item-1:checked ~ .cards #utilitie-1,
  #item-2:checked ~ .cards #utilitie-2,
  #item-3:checked ~ .cards #utilitie-3,
  #item-4:checked ~ .cards #utilitie-4,
  #item-5:checked ~ .cards #utilitie-5 {
    transform: translatex(0) scale(1);
    opacity: 1;
    z-index: 2;
  }

  #item-1:checked ~ .dots label[for="item-1"],
  #item-2:checked ~ .dots label[for="item-2"],
  #item-3:checked ~ .dots label[for="item-3"],
  #item-4:checked ~ .dots label[for="item-4"],
  #item-5:checked ~ .dots label[for="item-5"] {
    transform: scale(2);
  }
} */
@media (min-width: 1024px) {
  .card {
    position: absolute;
    width: 380px;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform 0.4s ease;
    cursor: pointer;
  }

  .dots {
    display: none;
  }

  #item-1:checked ~ .cards #utilitie-5,
  #item-2:checked ~ .cards #utilitie-1,
  #item-3:checked ~ .cards #utilitie-2,
  #item-4:checked ~ .cards #utilitie-3,
  #item-5:checked ~ .cards #utilitie-4 {
    transform: translatex(-300px) scale(0.8);
    opacity: 0.6;
    z-index: 1;
  }
  #item-1:checked ~ .cards #utilitie-4,
  #item-2:checked ~ .cards #utilitie-5,
  #item-3:checked ~ .cards #utilitie-1,
  #item-4:checked ~ .cards #utilitie-2,
  #item-5:checked ~ .cards #utilitie-3 {
    transform: translatex(-450px) scale(0.7);
    opacity: 0.2;
    z-index: 0;
  }

  #item-1:checked ~ .cards #utilitie-2,
  #item-2:checked ~ .cards #utilitie-3,
  #item-3:checked ~ .cards #utilitie-4,
  #item-4:checked ~ .cards #utilitie-5,
  #item-5:checked ~ .cards #utilitie-1 {
    transform: translatex(300px) scale(0.8);
    opacity: 0.6;
    z-index: 1;
  }
  #item-1:checked ~ .cards #utilitie-3,
  #item-2:checked ~ .cards #utilitie-4,
  #item-3:checked ~ .cards #utilitie-5,
  #item-4:checked ~ .cards #utilitie-1,
  #item-5:checked ~ .cards #utilitie-2 {
    transform: translatex(450px) scale(0.7);
    opacity: 0.2;
    z-index: 0;
  }

  #item-1:checked ~ .cards #utilitie-1,
  #item-2:checked ~ .cards #utilitie-2,
  #item-3:checked ~ .cards #utilitie-3,
  #item-4:checked ~ .cards #utilitie-4,
  #item-5:checked ~ .cards #utilitie-5 {
    transform: translatex(0) scale(1);
    opacity: 1;
    z-index: 2;
  }
}
</style>
