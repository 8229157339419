<template>
  <div class="pt-150 lg:pt-300 mb-60 px-6 lg:px-275">
    <div class="flex flex-col">
      <h2 class="uppercase text-white font-black text-5xl lg:text-10xl mb-30">
        Team
      </h2>
      <span
        class="text-base lg:text-lg text-white tracking-wider uppercase leading-3 lg:leading-10"
        >Real people, behind a real project.
      </span>
    </div>

    <div
      class="grid grid-col-2 lg:grid-col-3 grid-row-4 lg:grid-rows-2 gap-x-6 gap-y-6 lg:gap-x-60 lg:gap-y-90 lg:w-795 mt-30 lg:mt-60"
    >
      <div
        class="flex flex-col w-150 lg:w-255 row-start-1 col-start-1 row-end-2 col-end-2"
      >
        <div class="relative">
          <img
            src="../../public/team/team-leandro.svg"
            alt="leandro-team"
            class="w-full"
          />
          <a href="https://www.linkedin.com/in/leandrovitale/" target="_blank">
            <img
              src="../assets/icons rrss/in-black&white.svg"
              alt="linkedin"
              class="absolute -bottom-3 right-14"
            />
          </a>
          <a href="https://twitter.com/lean_vitale" target="_blank">
            <img
              src="../assets/icons rrss/tw-black&white.svg"
              alt="twitter"
              class="absolute -bottom-3 right-4"
            />
          </a>
        </div>
        <span
          class="text-xs lg:text-lg text-white tracking-widest uppercase mt-4 leading-9"
          >Leandro Vitale</span
        >
        <span
          class="text-xs lg:text-base text-white font-extralight tracking-widest leading-5"
          >Co-Founder, Full-Stack & Blockchain Developer</span
        >
      </div>

      <div
        class="flex flex-col w-150 lg:w-255 row-start-1 col-start-2 row-end-2 col-end-3"
      >
        <div class="relative">
          <img
            src="../../public/team/team-julieta.svg"
            alt="julieta-team"
            class="w-full"
          />
          <a
            href="https://www.linkedin.com/in/julieta-fredes-/"
            target="_blank"
          >
            <img
              src="../assets/icons rrss/in-black&white.svg"
              alt="linkedin"
              class="absolute -bottom-3 right-14"
            />
          </a>
          <a href="https://twitter.com/JulietaFredes4" target="_blank">
            <img
              src="../assets/icons rrss/tw-black&white.svg"
              alt="twitter"
              class="absolute -bottom-3 right-4"
            />
          </a>
        </div>
        <span
          class="text-xs lg:text-lg text-white tracking-widest uppercase mt-4 leading-9"
          >Julieta Fredes</span
        >
        <span
          class="text-xs lg:text-base text-white font-extralight tracking-widest leading-5"
          >Co-Founder, Web Developer & Community Manager</span
        >
      </div>

      <div
        class="flex flex-col w-150 lg:w-255 row-start-2 col-start-1 row-end-3 col-end-2 lg:row-start-1 lg:col-start-3 lg:row-end-2 lg:col-end-4"
      >
        <div class="relative">
          <img
            src="../../public/team/team-pipi.svg"
            alt="pipi-team"
            class="w-full"
          />
          <a href="https://www.linkedin.com/in/yamina-pipolo/" target="_blank">
            <img
              src="../assets/icons rrss/in-black&white.svg"
              alt="linkedin"
              class="absolute -bottom-3 right-14"
            />
          </a>
          <a href="https://twitter.com/YaminaPipolo" target="_blank">
            <img
              src="../assets/icons rrss/tw-black&white.svg"
              alt="twitter"
              class="absolute -bottom-3 right-4"
            />
          </a>
        </div>
        <span
          class="text-xs lg:text-lg text-white tracking-widest uppercase mt-4 leading-9"
          >Yamina Pipolo</span
        >
        <span
          class="text-xs lg:text-base text-white font-extralight tracking-widest leading-5"
          >Co-Founder, Artist & UX/UI Designer</span
        >
      </div>

      <div
        class="flex flex-col w-150 lg:w-255 row-start-2 col-start-2 row-end-3 col-end-3 lg:row-start-2 lg:col-start-1 lg:row-end-3 lg:col-end-2"
      >
        <div class="relative">
          <img
            src="../../public/team/nacho.png"
            alt="nacho-team"
            class="w-full"
          />
          <a
            href="https://www.linkedin.com/in/juan-ignacio-roby-3357b7142/"
            target="_blank"
          >
            <img
              src="../assets/icons rrss/in-black&white.svg"
              alt="linkedin"
              class="absolute -bottom-3 right-4"
            />
          </a>
        </div>
        <span
          class="text-xs lg:text-lg text-white tracking-widest uppercase mt-4 leading-9"
          >Juan Ignacio Roby</span
        >
        <span
          class="text-xs lg:text-base text-white font-extralight tracking-widest leading-5"
          >Legal Designer</span
        >
      </div>

      <div
        class="flex flex-col w-150 lg:w-255 row-start-3 col-start-1 row-end-4 col-end-2 lg:row-start-2 lg:col-start-2 lg:row-end-3 lg:col-end-3"
      >
        <div class="relative">
          <img
            src="../../public/team/team-virginia.svg"
            alt="virginia-team"
            class="w-full"
          />
          <a
            href="https://www.linkedin.com/in/virginia-mar%C3%ADa-fragapane-40a354104/"
            target="_blank"
          >
            <img
              src="../assets/icons rrss/in-black&white.svg"
              alt="linkedin"
              class="absolute -bottom-3 right-14"
            />
          </a>
          <a href="https://www.instagram.com/vmfrgpn/" target="_blank">
            <img
              src="../assets/icons rrss/ig-black&white.svg"
              alt="instagram"
              class="absolute -bottom-3 right-4"
            />
          </a>
        </div>
        <span
          class="text-xs lg:text-lg text-white tracking-widest uppercase mt-4 leading-9"
          >Virginia Fragapane</span
        >
        <span
          class="text-xs lg:text-base text-white font-extralight tracking-widest leading-5"
          >Copywriter</span
        >
      </div>

      <div
        class="flex flex-col w-150 lg:w-255 row-start-3 col-start-2 row-end-4 col-end-3 lg:row-start-2 lg:col-start-3 lg:row-end-3 lg:col-end-4"
      >
        <div class="relative">
          <img
            src="../../public/team/team-mariano.svg"
            alt="mariano-team"
            class="w-full"
          />
          <a href="https://www.instagram.com/marvit_music/" target="_blank">
            <img
              src="../assets/icons rrss/ig-black&white.svg"
              alt="instagram"
              class="absolute -bottom-3 right-14"
            />
          </a>
          <a href="https://twitter.com/MarianoVitale01" target="_blank">
            <img
              src="../assets/icons rrss/tw-black&white.svg"
              alt="twitter"
              class="absolute -bottom-3 right-4"
            />
          </a>
        </div>
        <span
          class="text-xs lg:text-lg text-white tracking-widest uppercase mt-4 leading-9"
          >Mariano Vitale</span
        >
        <span
          class="text-xs lg:text-base text-white font-extralight tracking-widest leading-5"
          >Lead Moderator in RRSS</span
        >
      </div>
    </div>

    <div class="flex flex-col lg:flex-row justify-center mt-60 lg:mt-120">
      <img
        src="../assets/icons/plus.svg"
        alt="plus"
        class="w-60 lg:w-90 lg:ml-30"
      />
      <div class="flex flex-col lg:ml-180 mt-6 lg:mt-0">
        <span
          class="text-base lg:text-lg text-white tracking-widest uppercase leading-10"
          >Work with us</span
        >
        <span
          class="text-base lg:text-lg text-white font-extralight tracking-widest leading-tight"
        >
          We are constantly growing. Send us your resume to
          hello@circumstancer.com
        </span>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row justify-center mt-60 lg:mt-150">
      <div class="flex flex-col lg:mr-180 order-last lg:order-first">
        <span
          class="text-base lg:text-lg text-white tracking-widest uppercase lg:leading-10"
          >Get in touch with us!</span
        >
        <span
          class="text-base lg:text-lg text-white font-extralight tracking-wider leading-tight"
        >
          We love talking to the community. Join our Discord channel to contact
          us directly.
        </span>
      </div>
      <a href="https://discord.gg/8hG5CvR3Wp" target="_blanck">
        <img
          src="../assets/icons rrss/discordMega-white.svg"
          alt="plus"
          class="w-60 lg:w-90 mb-30 lg:mb-0 lg:mr-30 order-first lg:order-last"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Team",
};
</script>
