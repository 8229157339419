<template>
  <div>
    <!-- Modal Transaction Process -->
    <div
      id="transactionModal"
      class="hidden overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-28 z-50 justify-center items-center inset-0 h-modal"
    >
      <div class="relative px-4 w-full max-w-md h-full">
        <!-- Modal content -->

        <div class="relative bg-white rounded-lg shadow">
          <!-- Modal header -->

          <div class="flex items-center p-6 pl-9 rounded-t border-b">
            <h3 class="text-lg font-medium">Transfer in Progress</h3>
            <button
              @click="showModal(false)"
              type="button"
              class="inline-flex items-center p-1.5 ml-auto text-sm text-gray-400 bg-transparent rounded-lg hover:bg-gray-200 hover:text-gray-900"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <!-- Progress Stepper -->

          <Stepper></Stepper>

          <!-- Model body -->

          <div class="flex flex-col items-center p-3 px-6 space-y-6">
            <Loader></Loader>
            <p class="text-lg font-medium">Transaction in Progress</p>
            <p
              class="text-base font-light leading-relaxed text-gray-500 text-center"
            >
              Ethereum transactions can take longer time to complete based upon
              network congestion. Please wait or increase the gas price of the
              transaction.
            </p>
          </div>

          <!-- Modal footer -->

          <div
            class="text-center p-3 space-x-2 rounded-b border-t border-gray-200"
          >
            <a href="#" target="_blank" class="text-pink1 text-sm"
              >View in Etherscan</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stepper from "./Stepper.vue";
import Loader from "./Loader.vue";

export default {
  name: "ModalTransaction",
  components: { Stepper, Loader },
  methods: {
    showModal(flag) {
      window.toggleModal("transactionModal", flag);
    },
  },
};
</script>
