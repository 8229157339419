<template>
  <div class="mt-0 lg:mt-60 flex flex-col text-center lg:text-left">
    <!-- Renglon fucsia -->

    <div class="lg:flex lg:items-center">
      <div
        class="w-full lg:w-945 h-150 lg:h-120 bg-pink1 lg:px-60 px-15 lg:flex lg:items-center text-center lg:text-left"
      >
        <div class="lg:mr-60">
          <span
            class="font-black text-white text-8xl lg:text-9xl text-center tracking-widest"
            >6.000</span
          >
        </div>
        <div class="w-350 h-150 lg:w-423 lg:h-100 mx-auto">
          <span
            class="font-extralight text-white text-3xl lg:text-8xl tracking-widest leading-10 lg:leading-16 uppercase"
            >Circumstancers on the way</span
          >
        </div>
      </div>
      <ButtonWallet v-if="hasMetamask"></ButtonWallet>
      <a href="https://metamask.io/" target="_blank" v-else>
        <button
          class="lg:ml-30 w-300 lg:w-350 h-60 my-30 border-3 border-black rounded-c text-base lg:text-lg uppercase hover:bg-black hover:text-white"
        >
          Install Metamask
        </button>
      </a>
    </div>

    <!-- Renglon verde -->

    <div class="flex flex-col lg:flex-row lg:items-center lg:justify-end">
      <div class="text-center order-last lg:order-first">
        <ButtonMint></ButtonMint>
      </div>
      <div
        class="w-full lg:w-885 h-150 lg:h-120 bg-green1 lg:self-end px-15 lg:px-60 flex flex-col lg:flex-row items-center justify-center"
      >
        <!-- <div class="lg:mr-60 notranslate">
          <CountDown></CountDown>
        </div> -->
        <div class="lg:w-screen lg:h-100 lg:flex lg:items-center">
          <span
            class="font-extralight text-black text-3xl lg:text-8xl tracking-widest leading-10 lg:leading-16 uppercase"
            >Coming soon..</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ButtonWallet from "./ButtonWallet.vue";
import ButtonMint from "./ButtonMint.vue";
// import CountDown from "./CountDown.vue";

export default {
  name: "Announcement",
  components: { ButtonWallet, ButtonMint },
  data() {
    return {
      hashTxt: null,
    };
  },
  computed: {
    ...mapState([
      "hasMetamask",
      "isSupportedRed",
      "isConnected",
      "minAccount",
      "circumstancerSC",
      "currentAccount",
    ]),
    disableBtn() {
      return (
        !this.isSupportedRed ||
        !this.isConnected ||
        !this.circumstancerSC ||
        this.isMinting
      );
    },
  },
};
</script>
