<template>
  <div class="mx-auto">
    <div class="border-b border-t border-1 border-gray-400 relative">
      <img
        src="../assets/369project.svg"
        alt="project"
        class="hidden lg:inline-block lg:w-full"
      />
      <img
        src="../assets/369Project-mobile.svg"
        alt="project"
        class="lg:hidden inline-block w-full"
      />
      <router-link to="/land">
        <button
          class="flex justify-center items-center bg-transparent text-white font-light text-2xl lg:text-4xl uppercase absolute bottom-20 right-17 lg:bottom-10 lg:right-54"
        >
          Read about 369
          <img
            src="../assets/icons/arrow-right-light.svg"
            alt="arrow"
            class="w-30 lg:w-60 ml-15 lg:ml-30"
          />
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Project",
};
</script>
