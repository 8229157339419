<template>
  <div>
    <button
      :disabled="true"
      :class="{
        'border-gray2 text-gray2 cursor-not-allowed': disableBtn,
        'border-black text-black hover:bg-black hover:text-white ': !disableBtn,
      }"
      @click="buyNft(true)"
      class="lg:mr-30 w-300 lg:w-350 h-60 my-30 border-3 border-black rounded-c text-base lg:text-lg font-light uppercase"
    >
      Shut up and take my money
    </button>
    <ModalMint></ModalMint>
    <ModalTransaction></ModalTransaction>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModalMint from "./ModalMint.vue";
import ModalTransaction from "./ModalTransaction.vue";

export default {
  name: "ButtonMint",
  components: { ModalMint, ModalTransaction },
  data() {
    return {
      hashTxt: null,
      isMinting: false,
      errMsg: null,
      completed: false,
      showModal: false,
      quantity: 1,
    };
  },
  computed: {
    ...mapState([
      "hasMetamask",
      "isSupportedRed",
      "isConnected",
      "minAccount",
      "circumstancerSC",
      "currentAccount",
    ]),
    disableBtn() {
      return (
        !this.isSupportedRed ||
        !this.isConnected ||
        !this.circumstancerSC ||
        this.isMinting
      );
    },
  },
  methods: {
    buyNft(flag) {
      window.toggleModal("mintModal", flag);
    },
  },
};
</script>
