<template>
  <div class="text-center">
    <button
      @click="INIT_METAMASK()"
      :disabled="!isSupportedRed || isConnected"
      :class="{
        'border-gray2 text-gray2 cursor-not-allowed': !isSupportedRed,
        'border-black text-black hover:bg-black hover:text-white':
          !isConnected && isSupportedRed,
        'border-black bg-black text-white font-medium cursor-not-allowed':
          isConnected,
      }"
      class="lg:ml-30 w-225 lg:w-275 h-60 lg:h-60 my-30 border-3 rounded-c text-base lg:text-lg font-light uppercase border-black"
    >
      {{
        isSupportedRed
          ? isConnected
            ? `Connected: ${minAccount}`
            : "Connect Wallet"
          : "Change Network"
      }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ButtonWallet",
  computed: {
    ...mapState(["isSupportedRed", "isConnected", "minAccount"]),
    disableBtn() {
      return !this.isSupportedRed || !this.isConnected;
    },
  },
  methods: {
    ...mapActions(["INIT_METAMASK"]),
  },
};
</script>
