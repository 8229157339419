<template>
  <div
    class="flex flex-col lg:flex-row lg:justify-between mx-0 lg:mx-60 items-center my-60"
  >
    <span
      class="text-base font-extralight order-last lg:order-first mt-30 lg:mt-0"
      >A real project made by real people</span
    >
    <div class="flex items-center order-first lg:order-2 w-270 justify-between">
      <img
        src="../assets/logo/logo-369-black.svg"
        alt="Logo 369"
        class="mr-30 lg:mr-15"
      />
      <img
        src="../assets/logo/logo-circumstancer-black.svg"
        alt="Logo Circumstancer"
        class="ml-15"
      />
    </div>
    <div
      class="flex items-center order-2 lg:order-last w-270 lg:w-180 justify-between mt-30 lg:mt-0"
    >
      <a
        href="https://etherscan.io/address/0x4a9cbDE757660507cE30ab7792732D456b117fdB"
        target="_blank"
        ><img
          src="../assets/icons rrss/etherscan-black.svg"
          alt="Etherscan link"
          class="lg:inline-block lg:mx-15"
      /></a>
      <a href="https://discord.gg/9mRcJSHmh9" target="_blank"
        ><img
          src="../assets/icons rrss/discord-black.svg"
          alt="Discord link"
          class="lg:inline-block"
      /></a>
      <a
        href="https://www.instagram.com/circumstancer_/"
        target="_blank"
        class="mx-15 lg:inline-block"
        ><img
          src="../assets/icons rrss/instagram-black.svg"
          alt="Instagram link"
      /></a>
      <a href="https://twitter.com/Circumstancer_" target="_blank"
        ><img
          src="../assets/icons rrss/twitter-black.svg"
          alt="Twitter link"
          class="lg:inline-block"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
