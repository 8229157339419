<template>
  <div>
    <div class="bg-gray1 pt-45 pb-0 lg:pb-60">
      <Header></Header>
    </div>
    <div class="bg-gray1 px-0 py-15 lg:p-15">
      <Announcement></Announcement>
    </div>
    <div class="bg-gray1 p-15">
      <Circumstancer></Circumstancer>
    </div>
    <div class="bg-gray1 p-15">
      <Decentralization></Decentralization>
    </div>

    <div id="utilities" class="bg-black p-15">
      <Utilities></Utilities>
    </div>
    <div id="project" class="bg-black">
      <Project></Project>
    </div>
    <div id="roadmap" class="bg-black">
      <RoadMap></RoadMap>
    </div>

    <div class="bg-black lg:p-15 px-0 py-15">
      <Team></Team>
      <FAQs></FAQs>
    </div>
    <div class="bg-gray1 p-15">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Announcement from "../components/Announcement.vue";
import Circumstancer from "../components/Circumstancer.vue";
import Decentralization from "../components/Decentralization.vue";
import Project from "../components/Project.vue";
import Utilities from "../components/Utilities.vue";
import RoadMap from "../components/RoadMap.vue";
import Team from "../components/Team.vue";
import Footer from "../components/Footer.vue";
import FAQs from "../components/FAQs.vue";

export default {
  name: "Home",
  components: {
    Header,
    Announcement,
    Circumstancer,
    Decentralization,
    Project,
    Utilities,
    RoadMap,
    Team,
    FAQs,
    Footer,
  },
};
</script>
