<template>
  <div
    class="grid grid-cols-2 grid-rows-3 gap-3 justify-items-center w-350 h-505 relative top-17"
  >
    <div
      class="w-165 h-165 border-3 border-solid border-white flex flex-col p-3 row-start-1 col-start-1 row-end-2 col-end-2 relative"
      @click="changeShow(1)"
      :class="{
        'bg-black text-white': !show1,
        'bg-white text-black ': show1,
      }"
    >
      <span
        :class="{
          ' text-white text-2xl text-left font-black': !show1,
          ' text-black text-xl text-left font-black': show1,
        }"
        >15%</span
      >
      <span
        v-if="!show1"
        class="text-white text-lg text-left font-light uppercase"
        >Entirely dedicated to 369</span
      >
      <img
        src="../assets/icons/arrow-right.svg"
        alt="tap"
        class="absolute bottom-3 right-3 w-15"
      />
      <span
        v-if="show1"
        :class="{ 'text-white': !show1, ' text-black': show1 }"
        class="text-xs text-left font-extralight mt-0"
        >• To get things right, you need to put in the time and effort. When we
        reach 15% of sales, the founding team will start dedicating full time to
        369 Labs.</span
      >
    </div>
    <div
      class="w-165 h-165 border-3 border-solid border-white flex flex-col p-3 row-start-1 col-start-2 row-end-2 col-end-3 relative"
      @click="changeShow(2)"
      :class="{ 'bg-black text-white': !show2, 'bg-white text-black': show2 }"
    >
      <span
        :class="{
          ' text-white text-2xl text-left font-black': !show2,
          ' text-black text-xl text-left font-black': show2,
        }"
        >30%</span
      >
      <span
        v-if="!show2"
        class="text-white text-lg text-left font-light uppercase"
        >Website and RRSS begins</span
      >
      <img
        src="../assets/icons/arrow-right.svg"
        alt="tap"
        class="absolute bottom-3 right-3 w-15"
      />
      <span
        v-if="show2"
        :class="{ 'text-white': !show2, ' text-black': show2 }"
        class="text-xs text-left font-extralight mt-0"
        >• With the completion of 30% of the sales, we will start the
        development of the 369 website and we will open new social media and a
        blog exclusively for the project.</span
      >
    </div>
    <div
      class="w-165 h-165 border-3 border-solid border-white flex flex-col p-3 row-start-2 col-start-1 row-end-3 col-end-2 relative"
      @click="changeShow(3)"
      :class="{ 'bg-black text-white': !show3, 'bg-white text-black': show3 }"
    >
      <span
        :class="{
          ' text-white text-2xl text-left font-black': !show3,
          ' text-black text-xl text-left font-black': show3,
        }"
        >45%</span
      >
      <span
        v-if="!show3"
        class="text-white text-lg text-left font-light uppercase"
        >369 Factory and Incubator are open</span
      >
      <img
        src="../assets/icons/arrow-right.svg"
        alt="tap"
        class="absolute bottom-3 right-3 w-15"
      />
      <span
        v-if="show3"
        :class="{ 'text-white': !show3, ' text-black': show3 }"
        class="text-xs text-left font-extralight mt-0"
        >• We will inaugurate 369 Factory and open applications for 369
        Incubator. Together with the community, we will analyze and select the
        projects.</span
      >
    </div>
    <div
      class="w-165 h-165 border-3 border-solid border-white flex flex-col p-3 row-start-2 row-end-3 col-start-2 col-end-3 relative"
      @click="changeShow(4)"
      :class="{ 'bg-black text-white': !show4, 'bg-white text-black': show4 }"
    >
      <span
        :class="{
          ' text-white text-2xl text-left font-black': !show4,
          ' text-black text-xl text-left font-black': show4,
        }"
        >60%</span
      >
      <span
        v-if="!show4"
        class="text-white text-lg text-left font-light uppercase"
        >The next project will start</span
      >
      <img
        src="../assets/icons/arrow-right.svg"
        alt="tap"
        class="absolute bottom-3 right-3 w-15"
      />
      <span
        v-if="show4"
        :class="{ 'text-white': !show4, ' text-black': show4 }"
        class="text-xs text-left font-extralight mt-0"
        >• Time to start a second NFT collection! The project will be selected
        together with the community.</span
      >
    </div>
    <div
      class="w-165 h-165 border-3 border-solid border-white flex flex-col p-3 row-start-3 row-end-4 col-start-1 col-end-2 relative"
      @click="changeShow(5)"
      :class="{ 'bg-black text-white': !show5, 'bg-white text-black': show5 }"
    >
      <span
        :class="{
          ' text-white text-2xl text-left font-black': !show5,
          ' text-black text-xl text-left font-black': show5,
        }"
        >75%</span
      >
      <span
        v-if="!show5"
        class="text-white text-lg text-left font-light uppercase"
        >MAGNA Token begins</span
      >
      <img
        src="../assets/icons/arrow-right.svg"
        alt="tap"
        class="absolute bottom-3 right-3 w-15"
      />
      <span
        v-if="show5"
        :class="{ 'text-white': !show5, ' text-black': show5 }"
        class="text-xs text-left font-extralight mt-0"
        >• When we reach 75% of sales, we will begin to execute our most
        ambitious and longed-for goal: MAGNA Token.</span
      >
    </div>
    <div
      class="w-165 h-165 border-3 border-solid border-white flex flex-col p-3 row-start-3 row-end-4 col-start-2 col-end-3 relative"
      @click="changeShow(6)"
      :class="{ 'bg-black text-white': !show6, 'bg-white text-black': show6 }"
    >
      <span
        :class="{
          ' text-white text-2xl text-left font-black': !show6,
          ' text-black text-xl text-left font-black': show6,
        }"
        >100%</span
      >
      <span
        v-if="!show6"
        class="text-white text-lg text-left font-light uppercase"
        >Formalizing 369</span
      >
      <img
        src="../assets/icons/arrow-right.svg"
        alt="tap"
        class="absolute bottom-3 right-3 w-15"
      />
      <span
        v-if="show6"
        :class="{ 'text-white': !show6, ' text-black': show6 }"
        class="text-xs text-left font-extralight mt-0"
        >• Our ambition is big and so is this project. Upon completion of the
        sale of Circumstancer, we will formalize the entity of 369 Land and our
        entire team.</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "RoadMapMobile",
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
    };
  },
  methods: {
    changeShow(show) {
      switch (show) {
        case 1:
          this.show1 = !this.show1;
          this.show2 = false;
          this.show3 = false;
          this.show4 = false;
          this.show5 = false;
          this.show6 = false;
          break;
        case 2:
          this.show1 = false;
          this.show2 = !this.show2;
          this.show3 = false;
          this.show4 = false;
          this.show5 = false;
          this.show6 = false;
          break;
        case 3:
          this.show1 = false;
          this.show2 = false;
          this.show3 = !this.show3;
          this.show4 = false;
          this.show5 = false;
          this.show6 = false;
          break;
        case 4:
          this.show1 = false;
          this.show2 = false;
          this.show3 = false;
          this.show4 = !this.show4;
          this.show5 = false;
          this.show6 = false;
          break;
        case 5:
          this.show1 = false;
          this.show2 = false;
          this.show3 = false;
          this.show4 = false;
          this.show5 = !this.show5;
          this.show6 = false;
          break;
        case 6:
          this.show1 = false;
          this.show2 = false;
          this.show3 = false;
          this.show4 = false;
          this.show5 = false;
          this.show6 = !this.show6;
          break;
      }
    },
  },
};
</script>
