<template>
  <div>
    <video
      src="../assets/videos/header-desktop.mp4"
      autoplay
      loop
      muted
      playsinline
      class="hidden lg:block lg:w-full"
    />

    <video
      src="../assets/videos/header-mobile.mp4"
      autoplay
      loop
      muted
      playsinline
      class="inline-block w-screen lg:hidden"
    ></video>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>
