<template>
  <div>
    <div
      id="mintModal"
      class="hidden overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-28 z-50 justify-center items-center inset-0 h-modal"
    >
      <div class="relative px-4 w-full max-w-md h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow">
          <!-- Modal header -->
          <div class="flex items-center p-6 pl-9 rounded-t border-b">
            <h3 class="text-lg font-medium">Mint your Circumstancer</h3>
            <button
              @click="buyNft(false)"
              type="button"
              class="inline-flex items-center p-1.5 ml-auto text-sm text-gray-400 bg-transparent rounded-lg hover:bg-gray-200 hover:text-gray-900"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <!-- Modal body -->

          <div
            class="flex items-center justify-between p-6 pl-9 rounded-t border-b"
          >
            <h3 class="text-lg font-light mr-6">I want to buy</h3>
            <select
              id="nft"
              v-model="quantity"
              class="block w-1/6 p-2.5 bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-black focus:border-black"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </div>
          <div
            class="flex items-center justify-between p-6 pl-9 rounded-t border-b"
          >
            <h3 class="text-lg font-light">Total Amount:</h3>
            <span class="text-lg font-light">{{ getTotalAmount }} ETH</span>
          </div>

          <!-- Modal footer -->
          <div
            class="flex items-center justify-center p-6 space-x-2 rounded-b border-t border-gray-200"
          >
            <button
              @click="mint()"
              data-modal-toggle="small-modal"
              type="button"
              class="font-light w-2/4 text-base px-5 py-2.5 text-center border-3 border-black rounded-c bg-white hover:bg-black hover:text-white uppercase"
            >
              Mint
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "MintModal",
  data() {
    return {
      hashTxt: null,
      isMinting: false,
      errMsg: null,
      completed: false,
      showModal: false,
      quantity: 1,
    };
  },
  computed: {
    ...mapState([
      "hasMetamask",
      "isSupportedRed",
      "isConnected",
      "minAccount",
      "circumstancerSC",
      "currentAccount",
    ]),
    getTotalAmount() {
      return this.quantity * 0.21;
    },
  },
  methods: {
    mint() {
      if (this.isConnected && this.isSupportedRed && this.circumstancerSC) {
        this.isMinting = true;
        this.circumstancerSC.methods
          .mint(this.quantity)
          .send({
            from: this.currentAccount,
            value: 21 * 1e16 * this.quantity,
          })
          .on("transactionHash", (hashTxt) => {
            this.showModal = true;
            this.hashTxt = hashTxt;
          })
          .on("receipt", () => {
            this.completed = true;
            this.isMinting = false;
          })
          .on("error", (err) => {
            this.showModal = true;
            this.isMinting = false;
            this.errMsg = err.message;
          });
      }
    },
    buyNft(flag) {
      window.toggleModal("mintModal", flag);
    },
  },
};
</script>
