var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-350 lg:w-795 lg:h-615 m-auto pt-90 relative"},[_vm._m(0),_c('RoadMapMobile',{staticClass:"grid lg:hidden"}),_c('div',{staticClass:"hidden lg:grid grid-col-3 grid-rows-2 gap-15 w-795 h-496 relative top-40"},[_c('div',{staticClass:"w-255 h-240 border border-solid border-white flex flex-col p-30 row-start-1 col-start-1 row-end-2 col-end-2 z-30 box1 justify-start",on:{"mouseover":function($event){_vm.show1 = true},"mouseleave":function($event){_vm.show1 = false},"mouseout":function($event){_vm.show1 = false}}},[(!_vm.show4)?_c('span',{class:{
          ' text-white  text-4xl text-left font-black': !_vm.show1,
          ' text-black text-4xl text-left font-black': _vm.show1,
        }},[_vm._v("15%")]):_vm._e(),(!_vm.show1 && !_vm.show4)?_c('span',{staticClass:"text-white text-2xl text-left font-light uppercase leading-14 mt-6"},[_vm._v("Entirely dedicated to 369")]):_vm._e(),(_vm.show1 && !_vm.show4)?_c('span',{staticClass:"text-black text-base text-left font-extralight leading-13 mt-6"},[_vm._v("• To get things right, you need to put in the time and effort. When we reach 15% of sales, the founding team will start dedicating full time to 369 Labs.")]):_vm._e()]),_c('div',{staticClass:"w-255 h-240 border border-solid border-white flex flex-col p-30 row-start-1 col-start-2 row-end-2 col-end-3 z-30 box2 justify-start",on:{"mouseover":function($event){_vm.show2 = true},"mouseleave":function($event){_vm.show2 = false},"mouseout":function($event){_vm.show2 = false}}},[(!_vm.show5)?_c('span',{class:{
          ' text-white text-4xl text-left font-black': !_vm.show2,
          ' text-black text-4xl text-left font-black': _vm.show2,
        }},[_vm._v("30%")]):_vm._e(),(!_vm.show2 && !_vm.show5)?_c('span',{staticClass:"text-white text-2xl text-left font-light uppercase leading-14 mt-6"},[_vm._v("Website and RRSS begins")]):_vm._e(),(_vm.show2 && !_vm.show5)?_c('span',{staticClass:"text-black text-base text-left font-extralight leading-13 mt-3"},[_vm._v(" • With the completion of 30% of the sales, we will start the development of the 369 website and we will open new social media and a blog exclusively for the project.")]):_vm._e()]),_c('div',{staticClass:"w-255 h-240 border border-solid border-white flex flex-col p-30 row-start-1 col-start-3 row-end-2 col-end-4 z-30 box3 justify-start",on:{"mouseover":function($event){_vm.show3 = true},"mouseleave":function($event){_vm.show3 = false},"mouseout":function($event){_vm.show3 = false}}},[(!_vm.show6)?_c('span',{class:{
          ' text-white text-4xl text-left font-black': !_vm.show3,
          ' text-black text-4xl text-left font-black': _vm.show3,
        }},[_vm._v("45%")]):_vm._e(),(!_vm.show3 && !_vm.show6)?_c('span',{staticClass:"text-white text-2xl text-left font-light uppercase leading-14 mt-6"},[_vm._v("369 Factory and Incubator are open")]):_vm._e(),(_vm.show3 && !_vm.show6)?_c('span',{staticClass:"text-black text-base text-left font-extralight leading-13 mt-6"},[_vm._v("• We will inaugurate 369 Factory and open applications for 369 Incubator. Together with the community, we will analyze and select the projects. ")]):_vm._e()]),_c('div',{staticClass:"w-255 lg:h-240 border border-solid border-white flex flex-col p-30 row-start-2 col-start-1 row-end-3 col-end-2 z-30 box4 justify-start",on:{"mouseover":function($event){_vm.show4 = true},"mouseleave":function($event){_vm.show4 = false},"mouseout":function($event){_vm.show4 = false}}},[(!_vm.show1)?_c('span',{class:{
          ' text-white text-4xl text-left font-black': !_vm.show4,
          ' text-black text-4xl text-left font-black': _vm.show4,
        }},[_vm._v("60%")]):_vm._e(),(!_vm.show4 && !_vm.show1)?_c('span',{staticClass:"text-white text-2xl text-left font-light uppercase leading-14 mt-6"},[_vm._v("The next project will start")]):_vm._e(),(_vm.show4 && !_vm.show1)?_c('span',{staticClass:"text-black text-base text-left font-extralight leading-13 mt-6"},[_vm._v("• Time to start a second NFT collection! The project will be selected together with the community. ")]):_vm._e()]),_c('div',{staticClass:"w-255 h-240 border border-solid border-white flex flex-col p-30 row-start-2 col-start-2 row-end-3 col-end-3 z-30 box5 justify-start",on:{"mouseover":function($event){_vm.show5 = true},"mouseleave":function($event){_vm.show5 = false},"mouseout":function($event){_vm.show5 = false}}},[(!_vm.show2)?_c('span',{class:{
          ' text-white text-4xl text-left font-black': !_vm.show5,
          ' text-black text-4xl text-left font-black': _vm.show5,
        }},[_vm._v("75%")]):_vm._e(),(!_vm.show5 && !_vm.show2)?_c('span',{staticClass:"text-white text-2xl text-left font-light uppercase leading-14 -0 mt-6"},[_vm._v(" MAGNA Token begins")]):_vm._e(),(_vm.show5 && !_vm.show2)?_c('span',{staticClass:"text-black text-base text-left font-extralight leading-13 mt-6"},[_vm._v("• When we reach 75% of sales, we will begin to execute our most ambitious and longed-for goal: MAGNA Token.")]):_vm._e()]),_c('div',{staticClass:"w-255 h-240 border border-solid border-white flex flex-col p-30 row-start-2 col-start-3 row-end-3 col-end-4 z-30 box6 justify-start",on:{"mouseover":function($event){_vm.show6 = true},"mouseleave":function($event){_vm.show6 = false},"mouseout":function($event){_vm.show6 = false}}},[(!_vm.show3)?_c('span',{class:{
          ' text-white text-4xl text-left font-black': !_vm.show6,
          ' text-black text-4xl text-left font-black': _vm.show6,
        }},[_vm._v("100%")]):_vm._e(),(!_vm.show6 && !_vm.show3)?_c('span',{staticClass:"text-white text-2xl text-left font-light uppercase leading-14 mt-6"},[_vm._v("Formalizing 369 ")]):_vm._e(),(_vm.show6 && !_vm.show3)?_c('span',{staticClass:"text-base text-left font-extralight leading-13 mt-6"},[_vm._v("• Our ambition is big and so is this project. Upon completion of the sale of Circumstancer, we will formalize the entity of 369 Land and our entire team. ")]):_vm._e()])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-end relative px-2 lg:px-0"},[_c('h2',{staticClass:"uppercase text-white font-black text-8xl lg:text-10xl absolute -bottom-9 lg:-bottom-32"},[_vm._v(" Road ")]),_vm._v(" > "),_c('h2',{staticClass:"uppercase text-white font-black text-2xl lg:text-8xl absolute -bottom-14 lg:-bottom-32"},[_vm._v(" Map ")]),_vm._v(" > ")])}]

export { render, staticRenderFns }