<template>
  <div class="h-350 flex gap-5 w-full overflow-x-auto px-6 slider">
    <div class="card">
      <!-- Holding -->

      <div
        class="w-255 h-300 lg:w-380 lg:h-420 bg-gray3 flex flex-col justify-start radio"
      >
        <div class="p-6 lg:p-10">
          <span
            class="text-3xl lg:text-8xl font-black text-red1 uppercase leading-14 lg:leading-16"
            >Get Tokens for Holding
          </span>
        </div>
        <div class="px-6 lg:px-10 pb-6 lg:pb-10 pt-3 lg:pt-0">
          <p class="text-white text-base lg:text-lg">
            As long as you own a Circumstancer NFT, you will passively emit our
            Utility Token: <b>MAGNA.</b>
          </p>
        </div>
      </div>
    </div>
    <div class="card">
      <!-- Vip Member -->

      <div
        class="w-255 h-300 lg:w-380 lg:h-420 bg-gray3 flex flex-col justify-start radio"
      >
        <div class="pt-6 px-6 lg:p-9 pr-10 lg:pr-28">
          <span
            class="text-4xl lg:text-8xl font-black text-yellow1 uppercase leading-14 lg:leading-16"
            >vip member</span
          >
        </div>
        <div class="px-6 lg:px-10 pb-6 lg:pb-10">
          <p class="text-white text-base lg:text-lg">
            Having a Circumstancer NFT makes you a VIP member of our community.
            Access the VIP section, take advantage of the utilities of your NFT,
            make use of the generated MAGNAs and get ready for many surprises!
          </p>
        </div>
      </div>
    </div>
    <div class="card">
      <!-- PRIORITY ACCESS:  -->

      <div
        class="w-255 h-300 lg:w-380 lg:h-420 bg-gray3 flex flex-col justify-start radio"
      >
        <div class="p-6 lg:p-10">
          <span
            class="text-4xl lg:text-8xl font-black text-pink1 uppercase leading-14 lg:leading-16"
            >PRIORITY ACCESS
          </span>
        </div>
        <div class="px-6 lg:px-10 pb-6 lg:pb-10">
          <p class="text-white text-base lg:text-lg">
            Access before anyone else to WL, Sweepstakes, pre-sale of all 369
            projects, and privileges to postulate your project to the community
            and have it developed by 369 Incubator.
          </p>
        </div>
      </div>
    </div>
    <div class="card">
      <!-- VOICE AND VOTE: -->

      <div
        class="w-255 h-300 lg:w-380 lg:h-420 bg-gray3 flex flex-col justify-start radio"
      >
        <div class="p-6 lg:p-10">
          <span
            class="text-4xl lg:text-8xl font-black text-green1 uppercase leading-14 lg:leading-16"
            >VOICE AND VOTE
          </span>
        </div>
        <div class="px-6 lg:px-10 pb-6 lg:pb-10">
          <p class="text-white text-base lg:text-lg">
            Vote, give your opinion, participate in decision making within 369.
            <br />Your voice counts in all cases: from alliances, projects to
            Incubate and launches.
          </p>
        </div>
      </div>
    </div>
    <div class="card">
      <!--  WHAT THE COMMUNITY WANTS? -->

      <div
        class="w-255 h-300 lg:w-380 lg:h-420 bg-gray3 flex flex-col justify-center radio"
      >
        <div class="p-6 lg:p-10">
          <span
            class="text-4xl lg:text-8xl font-black text-aqua uppercase leading-14 lg:leading-16"
            >WHAT THE COMMUNITY WANTS?
          </span>
        </div>
        <div class="px-6 lg:px-10 pb-6 lg:pb-10">
          <p class="text-white text-base lg:text-lg">
            We are open to suggestions and opinions from our community, many
            more things can be added and done as members request. Can you
            imagine?
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UtilitiesCarrouselMobile",
};
</script>

<style scoped>
.slider {
  scroll-snap-type: x proximity;
}

.radio {
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  scroll-snap-align: center;
}
</style>
