<template>
  <div class="flex justify-center items-center p-5 rounded-t border-b w-full">
    <div class="flex justify-center items-center p-9">
      <div class="container-stepper w-full absolute z-10">
        <ul class="progressbar">
          <li class="w-4/12 relative font-light text-sm text-center float-left">
            Approve
          </li>
          <li class="w-4/12 relative font-light text-sm text-center float-left">
            Confirmed
          </li>
          <li class="w-4/12 relative font-light text-sm text-center float-left">
            Completed
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Stepper",
};
</script>

<style scoped>
.progressbar li:before {
  content: "1";
  width: 30px;
  height: 30px;
}
.progressbar li:before {
  content: "1";
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
.progressbar {
  counter-reset: step;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #bebebe;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
/* 
} */

/* 
Esto en la linea que los une

.progressbar li.active + li:after {
  background: black;

Esto es el circulito
.buy-active para el :class segun como va la transaccion

.progressbar li.active + li:before {
  border-color: black;
  background: black;
  color: white;
} */
</style>
