var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid grid-cols-2 grid-rows-3 gap-3 justify-items-center w-350 h-505 relative top-17"},[_c('div',{staticClass:"w-165 h-165 border-3 border-solid border-white flex flex-col p-3 row-start-1 col-start-1 row-end-2 col-end-2 relative",class:{
      'bg-black text-white': !_vm.show1,
      'bg-white text-black ': _vm.show1,
    },on:{"click":function($event){return _vm.changeShow(1)}}},[_c('span',{class:{
        ' text-white text-2xl text-left font-black': !_vm.show1,
        ' text-black text-xl text-left font-black': _vm.show1,
      }},[_vm._v("15%")]),(!_vm.show1)?_c('span',{staticClass:"text-white text-lg text-left font-light uppercase"},[_vm._v("Entirely dedicated to 369")]):_vm._e(),_c('img',{staticClass:"absolute bottom-3 right-3 w-15",attrs:{"src":require("../assets/icons/arrow-right.svg"),"alt":"tap"}}),(_vm.show1)?_c('span',{staticClass:"text-xs text-left font-extralight mt-0",class:{ 'text-white': !_vm.show1, ' text-black': _vm.show1 }},[_vm._v("• To get things right, you need to put in the time and effort. When we reach 15% of sales, the founding team will start dedicating full time to 369 Labs.")]):_vm._e()]),_c('div',{staticClass:"w-165 h-165 border-3 border-solid border-white flex flex-col p-3 row-start-1 col-start-2 row-end-2 col-end-3 relative",class:{ 'bg-black text-white': !_vm.show2, 'bg-white text-black': _vm.show2 },on:{"click":function($event){return _vm.changeShow(2)}}},[_c('span',{class:{
        ' text-white text-2xl text-left font-black': !_vm.show2,
        ' text-black text-xl text-left font-black': _vm.show2,
      }},[_vm._v("30%")]),(!_vm.show2)?_c('span',{staticClass:"text-white text-lg text-left font-light uppercase"},[_vm._v("Website and RRSS begins")]):_vm._e(),_c('img',{staticClass:"absolute bottom-3 right-3 w-15",attrs:{"src":require("../assets/icons/arrow-right.svg"),"alt":"tap"}}),(_vm.show2)?_c('span',{staticClass:"text-xs text-left font-extralight mt-0",class:{ 'text-white': !_vm.show2, ' text-black': _vm.show2 }},[_vm._v("• With the completion of 30% of the sales, we will start the development of the 369 website and we will open new social media and a blog exclusively for the project.")]):_vm._e()]),_c('div',{staticClass:"w-165 h-165 border-3 border-solid border-white flex flex-col p-3 row-start-2 col-start-1 row-end-3 col-end-2 relative",class:{ 'bg-black text-white': !_vm.show3, 'bg-white text-black': _vm.show3 },on:{"click":function($event){return _vm.changeShow(3)}}},[_c('span',{class:{
        ' text-white text-2xl text-left font-black': !_vm.show3,
        ' text-black text-xl text-left font-black': _vm.show3,
      }},[_vm._v("45%")]),(!_vm.show3)?_c('span',{staticClass:"text-white text-lg text-left font-light uppercase"},[_vm._v("369 Factory and Incubator are open")]):_vm._e(),_c('img',{staticClass:"absolute bottom-3 right-3 w-15",attrs:{"src":require("../assets/icons/arrow-right.svg"),"alt":"tap"}}),(_vm.show3)?_c('span',{staticClass:"text-xs text-left font-extralight mt-0",class:{ 'text-white': !_vm.show3, ' text-black': _vm.show3 }},[_vm._v("• We will inaugurate 369 Factory and open applications for 369 Incubator. Together with the community, we will analyze and select the projects.")]):_vm._e()]),_c('div',{staticClass:"w-165 h-165 border-3 border-solid border-white flex flex-col p-3 row-start-2 row-end-3 col-start-2 col-end-3 relative",class:{ 'bg-black text-white': !_vm.show4, 'bg-white text-black': _vm.show4 },on:{"click":function($event){return _vm.changeShow(4)}}},[_c('span',{class:{
        ' text-white text-2xl text-left font-black': !_vm.show4,
        ' text-black text-xl text-left font-black': _vm.show4,
      }},[_vm._v("60%")]),(!_vm.show4)?_c('span',{staticClass:"text-white text-lg text-left font-light uppercase"},[_vm._v("The next project will start")]):_vm._e(),_c('img',{staticClass:"absolute bottom-3 right-3 w-15",attrs:{"src":require("../assets/icons/arrow-right.svg"),"alt":"tap"}}),(_vm.show4)?_c('span',{staticClass:"text-xs text-left font-extralight mt-0",class:{ 'text-white': !_vm.show4, ' text-black': _vm.show4 }},[_vm._v("• Time to start a second NFT collection! The project will be selected together with the community.")]):_vm._e()]),_c('div',{staticClass:"w-165 h-165 border-3 border-solid border-white flex flex-col p-3 row-start-3 row-end-4 col-start-1 col-end-2 relative",class:{ 'bg-black text-white': !_vm.show5, 'bg-white text-black': _vm.show5 },on:{"click":function($event){return _vm.changeShow(5)}}},[_c('span',{class:{
        ' text-white text-2xl text-left font-black': !_vm.show5,
        ' text-black text-xl text-left font-black': _vm.show5,
      }},[_vm._v("75%")]),(!_vm.show5)?_c('span',{staticClass:"text-white text-lg text-left font-light uppercase"},[_vm._v("MAGNA Token begins")]):_vm._e(),_c('img',{staticClass:"absolute bottom-3 right-3 w-15",attrs:{"src":require("../assets/icons/arrow-right.svg"),"alt":"tap"}}),(_vm.show5)?_c('span',{staticClass:"text-xs text-left font-extralight mt-0",class:{ 'text-white': !_vm.show5, ' text-black': _vm.show5 }},[_vm._v("• When we reach 75% of sales, we will begin to execute our most ambitious and longed-for goal: MAGNA Token.")]):_vm._e()]),_c('div',{staticClass:"w-165 h-165 border-3 border-solid border-white flex flex-col p-3 row-start-3 row-end-4 col-start-2 col-end-3 relative",class:{ 'bg-black text-white': !_vm.show6, 'bg-white text-black': _vm.show6 },on:{"click":function($event){return _vm.changeShow(6)}}},[_c('span',{class:{
        ' text-white text-2xl text-left font-black': !_vm.show6,
        ' text-black text-xl text-left font-black': _vm.show6,
      }},[_vm._v("100%")]),(!_vm.show6)?_c('span',{staticClass:"text-white text-lg text-left font-light uppercase"},[_vm._v("Formalizing 369")]):_vm._e(),_c('img',{staticClass:"absolute bottom-3 right-3 w-15",attrs:{"src":require("../assets/icons/arrow-right.svg"),"alt":"tap"}}),(_vm.show6)?_c('span',{staticClass:"text-xs text-left font-extralight mt-0",class:{ 'text-white': !_vm.show6, ' text-black': _vm.show6 }},[_vm._v("• Our ambition is big and so is this project. Upon completion of the sale of Circumstancer, we will formalize the entity of 369 Land and our entire team.")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }