<template>
  <div class="mt-30 lg:mt-120 mb-30 lg:mb-0">
    <img
      src="../assets/circumstancer-1.svg"
      alt="NFT"
      class="hidden lg:block lg:w-full"
    />
    <div
      class="flex flex-col lg:flex-row items-center justify-center w-full m-auto relative lg:mt-15 lg:mb-60 mx-auto lg:p-15"
    >
      <video
        src="../assets/videos/whoIs.mp4"
        autoplay
        loop
        muted
        playsinline
        class="w-270 h-270 lg:w-367 lg:h-367 order-first"
      />
      <div
        class="flex flex-col items-start order-last lg:order-2 w-screen lg:w-700 px-6 lg:px-0"
      >
        <span
          class="text-lg lg:text-2xl leading-6 text-center lg:text-left lg:leading-6 mt-20 lg:mt-12 mx-auto px-9 lg:px-0 font-medium tracking-widest"
          >Welcome to Circumstancer world.</span
        >

        <p
          class="text-base lg:text-lg leading-13 lg:leading-6 ml-0 lg:ml-60 mt-6 lg:mt-12 font-light order-last lg:order-2"
        >
          Circumstancer is a community of 6.000 NTF unique digital collectibles,
          programmatically generated from over 157 possible traits. It’s created
          on the Ethereum blockchain, stored as ERC-721 tokens and hosted on
          IPFS.
          <br />
          <br />
          Unusual situations are of no concern to Circumstancer. He faces any
          small challenge with simplicity. A world under the sea, aliens
          strolling through his neighborhood, the earth turning to lava… nothing
          can bend his pure personality. Perhaps a little smile or a passive
          gesture might appear, but that would be too much. <br />
          <br />
          Because that’s how he was intended from the beginning by our artist.
          Nothing can stop him. Circumstancer is ready for everything and is
          hungry for adventure. <br />
          <br />
          The mind is a white paper that longs to be drawn with the crayons of
          creativity. The shapes, colors and textures of Circumstancer are the
          result of the crazy head of an artist without limits. <br />
          <br />Are you ready to jump in?
        </p>
      </div>
      <div class="order-2 lg:order-last">
        <h2
          class="text-8xl lg:text-10xl uppercase absolute top-59 left-40 lg:top-100 lg:left-70 font-black"
        >
          Who is
        </h2>
        <h2
          class="text-2xl lg:text-4xl uppercase font-black absolute top-74 left-28 lg:top-105 lg:left-72"
        >
          Circumstancer
        </h2>
      </div>
    </div>
    <img
      src="../assets/circumstancer-2.svg"
      alt="NFT"
      class="hidden lg:block lg:w-full"
    />
  </div>
</template>

<script>
export default {
  name: "Circumstancer",
};
</script>
