<template>
  <div class="mt-10 lg:mt-210 mb-14 lg:mb-40">
    <h2
      class="uppercase text-white font-black text-3xl lg:text-5xl text-center"
    >
      Frequently Asked Questions
    </h2>

    <div class="mx-auto w-11/12 lg:w-615 mt-60">
      <div
        class="text-gray-800 bg-gray1 rounded-md p-4 mx-auto mt-4"
        v-for="item in listFAQ"
        :key="item.title"
      >
        <h2 class="text-md font-semibold">{{ item.title }}</h2>
        <p class="text-sm mt-2 font-light leading-6">{{ item.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listFAQ: [
        {
          title: "Our Smart Contract",
          description: "0x4a9cbDE757660507cE30ab7792732D456b117fdB  ",
        },
        {
          title: "How Can I Buy a Circumstancer?",
          description:
            "Right from our website!  And when the total supply (6.000 NF) is sold, the Smart Contract will be published on OpenSea as a new marketplace.",
        },
        {
          title: "Why should I do it?",
          description:
            "As long as you own a Circumstancer, you will passively emit MAGNA Token. Find out all the benefits of having a Circumstancer in the section UTILITIES.",
        },
        {
          title: "When is the pre-sale and what is the price?",
          description:
            "The price of each Circumstancer is 0,21 ETH. Join our Discord to be aware of the mint date. ",
        },
        {
          title: "How many Circumstancers can I buy in the pre-sale?",
          description: "In the pre-sale, you can buy until 3 Circumstancers.",
        },
        {
          title: "When is the mint and what is the price?",
          description:
            "The price of each Circumstancer is 0,48 ETH. Join our Discord to be aware of the mint date.",
        },
        {
          title: "How many Circumstancers can I mint?",
          description: "Maximum 6 per transaction. ",
        },
        {
          title: "How many Circumstancers are reserved?",
          description:
            "We reserve 100 Circumstancer for marketing events, giveaways, and partnerships.",
        },
        {
          title: "What is 369?",
          description:
            "369 is a dynamic startup expert in Blockchain NFT. We offer software factory,project incubation and tools to make your craziest ideas come true. Jump with us into the crypto world. Find out more about 369 in the section 369 LAND. ",
        },
        {
          title: "What does it mean to be a decentralized app?",
          description:
            "As any other Blockchain project, 369 is a decentralized app. That means it is not regulated by any information watchdog. Blockchain is uncensorable. ",
        },
        {
          title: "How can I enter the whitelist",
          description:
            "To enroll in the WL, you will have to assume the cost of the gas/fee for entering your wallet in the Smart Contract. This measure is to filter out people who are really interested in the project. Also, you will have to make some noise in our community and invite NFT friends to join us.  ",
        },
        {
          title: "Entering the whitelist guarantees me to buy an NFT?",
          description:
            "Being inside the WL does NOT guarantee you to buy a NFT. This is because there is a quota of 1500 people that can enter and 1800 NFT to sell but each wallet can buy up to 3 NFT. So you must pay attention to our Discord to find out the exact presale time and be right on time.  ",
        },
        {
          title: "How much are the royalty fees and how is their distribution?",
          description:
            "Resale, through the Open Sea. Royalty fees: 9% (4,5% for 369 and 4,5% for the Incubator Fund).  ",
        },
      ],
    };
  },
};
</script>
