<template>
  <div class="w-350 lg:w-795 lg:h-615 m-auto pt-90 relative">
    <div class="flex justify-end relative px-2 lg:px-0">
      <h2
        class="uppercase text-white font-black text-8xl lg:text-10xl absolute -bottom-9 lg:-bottom-32"
      >
        Road
      </h2>
      >
      <h2
        class="uppercase text-white font-black text-2xl lg:text-8xl absolute -bottom-14 lg:-bottom-32"
      >
        Map
      </h2>
      >
    </div>
    <RoadMapMobile class="grid lg:hidden"></RoadMapMobile>
    <div
      class="hidden lg:grid grid-col-3 grid-rows-2 gap-15 w-795 h-496 relative top-40"
    >
      <div
        class="w-255 h-240 border border-solid border-white flex flex-col p-30 row-start-1 col-start-1 row-end-2 col-end-2 z-30 box1 justify-start"
        @mouseover="show1 = true"
        @mouseleave="show1 = false"
        @mouseout="show1 = false"
      >
        <span
          v-if="!show4"
          :class="{
            ' text-white  text-4xl text-left font-black': !show1,
            ' text-black text-4xl text-left font-black': show1,
          }"
          >15%</span
        >
        <span
          v-if="!show1 && !show4"
          class="text-white text-2xl text-left font-light uppercase leading-14 mt-6"
          >Entirely dedicated to 369</span
        >
        <span
          v-if="show1 && !show4"
          class="text-black text-base text-left font-extralight leading-13 mt-6"
          >• To get things right, you need to put in the time and effort. When
          we reach 15% of sales, the founding team will start dedicating full
          time to 369 Labs.</span
        >
      </div>
      <div
        class="w-255 h-240 border border-solid border-white flex flex-col p-30 row-start-1 col-start-2 row-end-2 col-end-3 z-30 box2 justify-start"
        @mouseover="show2 = true"
        @mouseleave="show2 = false"
        @mouseout="show2 = false"
      >
        <span
          v-if="!show5"
          :class="{
            ' text-white text-4xl text-left font-black': !show2,
            ' text-black text-4xl text-left font-black': show2,
          }"
          >30%</span
        >
        <span
          v-if="!show2 && !show5"
          class="text-white text-2xl text-left font-light uppercase leading-14 mt-6"
          >Website and RRSS begins</span
        >
        <span
          v-if="show2 && !show5"
          class="text-black text-base text-left font-extralight leading-13 mt-3"
        >
          • With the completion of 30% of the sales, we will start the
          development of the 369 website and we will open new social media and a
          blog exclusively for the project.</span
        >
      </div>
      <div
        class="w-255 h-240 border border-solid border-white flex flex-col p-30 row-start-1 col-start-3 row-end-2 col-end-4 z-30 box3 justify-start"
        @mouseover="show3 = true"
        @mouseleave="show3 = false"
        @mouseout="show3 = false"
      >
        <span
          v-if="!show6"
          :class="{
            ' text-white text-4xl text-left font-black': !show3,
            ' text-black text-4xl text-left font-black': show3,
          }"
          >45%</span
        >
        <span
          v-if="!show3 && !show6"
          class="text-white text-2xl text-left font-light uppercase leading-14 mt-6"
          >369 Factory and Incubator are open</span
        >
        <span
          v-if="show3 && !show6"
          class="text-black text-base text-left font-extralight leading-13 mt-6"
          >• We will inaugurate 369 Factory and open applications for 369
          Incubator. Together with the community, we will analyze and select the
          projects.
        </span>
      </div>
      <div
        class="w-255 lg:h-240 border border-solid border-white flex flex-col p-30 row-start-2 col-start-1 row-end-3 col-end-2 z-30 box4 justify-start"
        @mouseover="show4 = true"
        @mouseleave="show4 = false"
        @mouseout="show4 = false"
      >
        <span
          v-if="!show1"
          :class="{
            ' text-white text-4xl text-left font-black': !show4,
            ' text-black text-4xl text-left font-black': show4,
          }"
          >60%</span
        >
        <span
          v-if="!show4 && !show1"
          class="text-white text-2xl text-left font-light uppercase leading-14 mt-6"
          >The next project will start</span
        >
        <span
          v-if="show4 && !show1"
          class="text-black text-base text-left font-extralight leading-13 mt-6"
          >• Time to start a second NFT collection! The project will be selected
          together with the community.
        </span>
      </div>
      <div
        class="w-255 h-240 border border-solid border-white flex flex-col p-30 row-start-2 col-start-2 row-end-3 col-end-3 z-30 box5 justify-start"
        @mouseover="show5 = true"
        @mouseleave="show5 = false"
        @mouseout="show5 = false"
      >
        <span
          v-if="!show2"
          :class="{
            ' text-white text-4xl text-left font-black': !show5,
            ' text-black text-4xl text-left font-black': show5,
          }"
          >75%</span
        >
        <span
          v-if="!show5 && !show2"
          class="text-white text-2xl text-left font-light uppercase leading-14 -0 mt-6"
        >
          MAGNA Token begins</span
        >
        <span
          v-if="show5 && !show2"
          class="text-black text-base text-left font-extralight leading-13 mt-6"
          >• When we reach 75% of sales, we will begin to execute our most
          ambitious and longed-for goal: MAGNA Token.</span
        >
      </div>
      <div
        class="w-255 h-240 border border-solid border-white flex flex-col p-30 row-start-2 col-start-3 row-end-3 col-end-4 z-30 box6 justify-start"
        @mouseover="show6 = true"
        @mouseleave="show6 = false"
        @mouseout="show6 = false"
      >
        <span
          v-if="!show3"
          :class="{
            ' text-white text-4xl text-left font-black': !show6,
            ' text-black text-4xl text-left font-black': show6,
          }"
          >100%</span
        >
        <span
          v-if="!show6 && !show3"
          class="text-white text-2xl text-left font-light uppercase leading-14 mt-6"
          >Formalizing 369
        </span>
        <span
          v-if="show6 && !show3"
          class="text-base text-left font-extralight leading-13 mt-6"
          >• Our ambition is big and so is this project. Upon completion of the
          sale of Circumstancer, we will formalize the entity of 369 Land and
          our entire team.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import RoadMapMobile from "./RoadMapMobile.vue";
export default {
  name: "RoadMap",
  components: { RoadMapMobile },
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
    };
  },
};
</script>
<style scoped>
.box1:hover,
.box2:hover,
.box3:hover {
  height: 31rem;
  background-color: white;
  transition: background-color 250ms linear;
  z-index: 2;
}
.box4:hover,
.box5:hover,
.box6:hover {
  height: 31rem;
  background-color: white;
  transition: background-color 250ms linear;
  position: absolute;
  top: -15.9rem;
  z-index: 1;
}
</style>
