<template>
  <div
    class="flex flex-col lg:flex-row lg:justify-between mt-15 lg:mt-120 lg:mx-150 mb-30 lg:mb-150"
  >
    <div class="relative mb-60 lg:mb-0">
      <img
        src="../assets/colors.svg"
        alt="decentralization"
        class="w-225 mx-auto lg:w-300"
      />
      <span
        class="text-8xl lg:text-10xl uppercase absolute top-47 left-44 lg:top-54 lg:left-54 font-black"
        >100%</span
      >
      <h2
        class="text-2xl lg:text-4xl uppercase font-black absolute top-62 left-26 lg:top-80 lg:left-40"
      >
        Decentralized
      </h2>
    </div>
    <div class="lg:self-center lg:w-3/6 flex flex-col items-center">
      <p class="text-base lg:text-lg leading-13 lg:leading-18 font-light">
        Circumstancer is a 100% decentralized app. We use the IPFS network for
        hosting our website, images and metadata. <br />
        This avoids censorship and at the time of mint, if the main server is
        overloaded, could choose any other to consume our site.
      </p>
      <br />
      <span
        class="text-base lg:text-lg leading-13 lg:leading-18 font-light self-start pr-12 lg:pr-0"
        >You can consume our site at any of the
        <a
          href="https://ipfs.github.io/public-gateway-checker/"
          target="_blank"
          class="text-base lg:text-lg leading-13 lg:leading-18 font-light underline text-blue-600"
          >IPFS Public Gateways</a
        >
        <br />
        <br />
        This is an example
        <a
          href="https://bafybeidefaftpp4ih3xohs7k7pazlpmdu6bewyfasdieoyr4a45gy2fxwm.ipfs.dweb.link/"
          target="_blanck"
          class="text-base lg:text-lg leading-13 lg:leading-18 font-light underline text-blue-600"
          >Try here</a
        >
        <br />
      </span>

      <button
        class="text-sm lg:text-base font-light uppercase leading-13 lg:leading-18 lg:mr-30 w-180 h-45 my-30 border-3 border-black rounded-lg text-black hover:bg-black hover:text-white"
        @click="copyClipboard()"
      >
        Copy Web CID
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Decentralization",
  data() {
    return {
      toaster: false,
    };
  },
  methods: {
    ...mapActions(["showToaster"]),
    copyClipboard() {
      navigator.clipboard
        .writeText(
          "bafybeidefaftpp4ih3xohs7k7pazlpmdu6bewyfasdieoyr4a45gy2fxwm"
        )
        .then(() => {
          this.showToaster({
            show: true,
            message: "Copied on Clipboard",
          });
        });
    },
  },
};
</script>
